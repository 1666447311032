<template>
  <v-list-item @click="goToDetail(gene)">
    <v-list-item-content>
      <v-list-item-title>
        <span class="font-weight-medium"> {{ gene.Symbol.toUpperCase() }}</span>
        {{
          (gene.Alternative_Symbols &&
            `- (${gene.Alternative_Symbols.join(", ")})`) ||
            (gene.Alternative_Names  && ` ${gene.Alternative_Names.join(", ")}`)
        }}
      </v-list-item-title>
      <v-list-item-subtitle class="">
        <strong>OMIM: </strong> {{ gene.OMIM  }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SearchbaseGeneResultItem",
  props: {
    gene: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    goToDetail(gene) {
      this.$router.push({
        name: "Explorer.gene_detail",
        params: { id: gene._id }
      });
    }
  }
};
</script>
