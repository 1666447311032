<template>
  <v-list-item @click="$router.push({ name: 'Explorer.drug_detail', params: { id: drug._id } })" two-line>
    <v-list-item-content>
      <v-list-item-title class="font-weight-medium">
        {{ drug['Drug_Name_EN']   }}
      </v-list-item-title>
      <v-list-item-subtitle class="">
        {{ drug.Brandnames && drug.Brandnames.slice(0,3).join(", ")}}...
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "ExplorerSearchbaseDrugResultItem",
  components: {},
  props: {
    drug: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
