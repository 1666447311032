<template>
<!-- AQUI INSERTASE O ELEMENTO QUE SE QUEIRA POR DE FONDO -->
<div id="fondo-custom">
  <img src="~@/assets/img/senora_parallax.jpg" alt="">
</div>
</template>

<script>
export default {
  computed: {
    cssVars() {
      return {
        "--detalle-color": `#${this.color}`
      };
    }
  }
}
</script>

<style lang="scss" scoped>
#fondo-custom {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
img {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  object-fit: cover;
  opacity: .2;
}
</style>
